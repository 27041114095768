/* Add your custom styles here */
.navbar {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.navbar.scrolled {
  background-color: #fff;
  color: rgb(12 28 44);
  /* Change this to the desired color */
}

.nav-link {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 15px 0;
  margin: 0px 15px;
}

.logo-hide {
  display: none;
}

@media screen and (max-width: 600px) {
  .logo-hide {
    display: inline;
  }
}