p {
  color: inherit;
  font-family: hind, Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #828b8d;
}

li {
  color: inherit;
  font-family: hind, Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  line-height: 1.3;
  margin-bottom: 20px;
  color: #828b8d;
  list-style-type: none;
}

.titre {
  color: #d3d3d3;
  font-family: serif;
  font-size: 5em;
  font-weight: 700;
  line-height: 1;
  opacity: 1;
  text-align: center;
  color: #fff;
  /* Set your desired text color */
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the alpha value for transparency */
}

.p {
  font-size: 2rem;
}



a,
a:hover {
  text-decoration: none;
  color: #5d93ff;
}

a {
  color: #5d93ff;
}

a:hover {
  text-decoration: none;
  list-style: none;
}



.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

/** css for transiction **/
.reveal {
  position: relative;
  opacity: 0;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

.marginTop{
  margin-top: 5%;
}

@media screen and (max-width: 600px) {
  .titre {
    color: #d3d3d3;
    font-family: serif;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1;
    opacity: 1;
    text-align: center;
  }
  .marginTop{
    margin-top: 15%;
  }

  .p {
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
  }

}